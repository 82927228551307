<template>
    <div>
      <!-- <b-sidebar text-variant="light" bg-variant="dark" id="sidebar-1" no-header backdrop shadow>
        <template>
        </template>
        <nav class="mt-3">
            <b-nav vertical type="light" variant="light">
              <b-nav-item :to="'/orders'"><span><font-awesome-icon icon="shopping-cart" class="icon"/></span> Orders</b-nav-item>
              <b-nav-item v-b-toggle.collapse-featured-products><span><font-awesome-icon :icon="['fas', 'medal']" class="icon"/></span>Produk Unggulan</b-nav-item>
              <b-collapse id="collapse-featured-products">
                  <b-nav-item :to="'/featured-products'"><span><font-awesome-icon icon="circle" class="icon"/></span>Listing</b-nav-item>
                  <b-nav-item :to="'/featured-products/create'"><span><font-awesome-icon icon="circle" class="icon"/></span>Tambah Baru</b-nav-item>
              </b-collapse>
            </b-nav>
          </nav>
      </b-sidebar> -->
      <b-navbar fixed="top" id="large-nav" class="hidden-sm-down" toggleable="lg">
            <!-- <b-navbar-brand v-b-toggle.sidebar-1> -->
            <b-navbar-toggle target="nav-collapse" class="border-0 mr-2"></b-navbar-toggle>
            <b-navbar-brand>
                <!-- <font-awesome-icon icon="bars" class="icon"/> -->
                <img src="assets/images/logo-width.png" alt="">
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav class="order-2 order-lg-1">
                <b-navbar-nav class="navbar-nav-left">
                    <!-- <b-nav-item href="#/">Dashboard</b-nav-item> -->
                    <b-nav-item-dropdown text="Pengaturan" menu-class="dropdown-menu-fit">
                        <b-dropdown-item href="#/agents">CS</b-dropdown-item>
                        <b-dropdown-item href="#/super-admins">User Admin</b-dropdown-item>
                    <!-- <b-dropdown-item href="#/setting/wa-message">Pengaturan Pesan Wa</b-dropdown-item> -->
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown text="Master Data" menu-class="dropdown-menu-fit">
                        <!-- <b-dropdown-item href="#/package-categories">Kategori Paket</b-dropdown-item> -->
                        <b-dropdown-item href="#/packages">Produk</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown text="Order" menu-class="dropdown-menu-fit">
                        <!-- <b-dropdown-item href="#/package-categories">Kategori Paket</b-dropdown-item> -->
                        <b-dropdown-item href="#/orders">Order</b-dropdown-item>
                        <b-dropdown-item href="#/orders/deleted-list">Order Terhapus</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item href="#/pengadaan">Pengadaan</b-nav-item>
                    <b-nav-item href="#/vendor">Vendor</b-nav-item>
                    <b-nav-item href="#/direksi" v-if="$store.getters['account/needPermission']('direksi.manage')">Direksi</b-nav-item>
                    <b-nav-item href="#/user" v-if="$store.getters['account/needPermission']('user.manage')">User</b-nav-item> -->
                    <!-- <b-nav-item href="#/users">Hak Akses</b-nav-item> -->
                </b-navbar-nav>
            </b-collapse>
            <b-navbar-nav id="nav-dropdown" class="navbar-nav-icons flex-row order-1 order-lg-2">
                <!-- <b-nav-item-dropdown ref="notificationDropdown" id="nav-notification" size="lg" right no-caret toggle-class="text-decoration-none rounded-circle toggle-icon mr-1" menu-class=" position-absolute" variant="link">
                    <template #button-content>
                        <font-awesome-icon icon="bell" /> <b-badge v-if="totalUnreadNotification > 0" class="notif-badge">{{totalUnreadNotification}}</b-badge>
                    </template>
                    <b-dropdown-item v-for="notification in notifications" :key="notification.id" :href="'#/pengadaan/' + notification.id_pengadaan" v-b-tooltip.hover.bottom="'Segera selesaikan proses PRK: ' + notification.pengadaan.nomor_prk" class="notif-item">
                        <p class="notif-text my-0">
                            <span class="message">{{notification.pengadaan.nama_prk}}</span>
                        </p>
                        <span class="notif-time">
                            <font-awesome-icon icon="clock" /> {{notification.created_at}}
                        </span>
                    </b-dropdown-item>
                </b-nav-item-dropdown> -->
                <b-nav-item-dropdown size="lg" right no-caret toggle-class="text-decoration-none rounded-circle toggle-icon" menu-class=" position-absolute" variant="link">
                    <template #button-content>
                        <!-- <font-awesome-icon icon="user-circle" /> -->
                        <div class="user-initial bg-primary text-light">{{$store.getters['account/accountData'].name.substring(0, 3)}}</div>
                    </template>
                    <b-dropdown-item href="#/setting">Pengaturan Akun</b-dropdown-item>
                    <b-dropdown-item @click.prevent="logOut">Sign Out</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
       </b-navbar>
    </div>
</template>

<script>
export default {
    data () {
      return {
        notifications: []
      }
    },
    computed: {
      totalUnreadNotification () {
        return this.notifications.filter(data => {
            return data.unread === true
        }).length
      }
    },
    methods: {
        logOut () {
            this.$swal.fire({
                title: 'Anda Yakin Akan Log Out?',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
            }).then(result => {
                if (result.value) {
                    this.$router.push('/logout')
                }
            })
        },
        getNotifications () {
            this.$http.get(process.env.VUE_APP_API_URL + 'notifications').then(resp => {
                this.notifications = []
                resp.data.data.forEach(notification => {
                    this.notifications.push({
                        'id': notification.id,
                        'id_pengadaan': notification.id_pengadaan,
                        'title': notification.title,
                        'unread': notification.unread,
                        'pengadaan': notification.pengadaan,
                        'created_at': this.$moment(notification.created_at).from(this.$moment().format())
                    })
                })
                // if (this.totalUnreadNotification) {
                //     this.$store.dispatch('notification/infoOnTop', {
                //         message: 'Anda memiliki '+ this.totalUnreadNotification +' pekerjaan tertunda.',
                //         callback: () => {
                //             this.$refs.notificationDropdown.show()
                //         }
                //     })
                // }
            })
        }
    },
    mounted () {
        if (this.$store.getters['account/isLoggedIn']) {
            // this.getNotifications()
        }
        if (process.env.VUE_APP_ENABLE_NOTIFICATION === '1') {
            this.$store.getters['account/firebaseMessaging'].onMessage(payload => {
                console.log(payload)
                // payload.data.title
                // payload.data.body
                // payload.data['gcm.notification.type']
                // payload.data['gcm.notification.type_id']
                // console.log('Message received. ', payload.data['gcm.notification.type_id'])
                // 'addToCart'
                this.$store.dispatch('notification/notification', {
                    title: payload.data.title,
                    message: payload.data.body,
                    redirect: '/#/orders?order_no=' + payload.data['gcm.notification.type_id']
                })
            })
        }
        this.$nextTick(() => {
            window.addEventListener('scroll', () => {
                if (this.$store.getters['account/isLoggedIn']) {
                    let scrollpos = window.scrollY
                    if (scrollpos > 0) {
                        document.querySelector('#large-nav').classList.add('shadow')
                    } else {
                        document.querySelector('#large-nav').classList.remove('shadow')
                    }
                }
            })
        })
    }
}
</script>