<template>
  <b-container fluid>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </b-container>
</template>

<script>
  export default {
    
  }
</script>